// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-posts-2020-05-image-fallback-component-image-fallback-component-mdx": () => import("./../../../src/posts/2020-05/image-fallback-component/image-fallback-component.mdx" /* webpackChunkName: "component---src-posts-2020-05-image-fallback-component-image-fallback-component-mdx" */),
  "component---src-posts-2020-08-how-to-be-productive-how-to-be-productive-mdx": () => import("./../../../src/posts/2020-08/how-to-be-productive/how-to-be-productive.mdx" /* webpackChunkName: "component---src-posts-2020-08-how-to-be-productive-how-to-be-productive-mdx" */),
  "component---src-posts-2020-11-how-to-position-electron-tray-how-to-position-electron-tray-mdx": () => import("./../../../src/posts/2020-11/how-to-position-electron-tray/how-to-position-electron-tray.mdx" /* webpackChunkName: "component---src-posts-2020-11-how-to-position-electron-tray-how-to-position-electron-tray-mdx" */),
  "component---src-posts-2020-12-creating-electron-react-app-creating-electron-react-app-mdx": () => import("./../../../src/posts/2020-12/creating-electron-react-app/creating-electron-react-app.mdx" /* webpackChunkName: "component---src-posts-2020-12-creating-electron-react-app-creating-electron-react-app-mdx" */),
  "component---src-posts-2021-01-making-decisions-with-decision-matrix-making-decisions-with-decision-matrix-mdx": () => import("./../../../src/posts/2021-01/making-decisions-with-decision-matrix/making-decisions-with-decision-matrix.mdx" /* webpackChunkName: "component---src-posts-2021-01-making-decisions-with-decision-matrix-making-decisions-with-decision-matrix-mdx" */),
  "component---src-posts-2021-03-finally-understanding-node-internals-finally-understanding-node-internals-mdx": () => import("./../../../src/posts/2021-03/finally-understanding-node-internals/finally-understanding-node-internals.mdx" /* webpackChunkName: "component---src-posts-2021-03-finally-understanding-node-internals-finally-understanding-node-internals-mdx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */)
}

